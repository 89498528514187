<template>
    <div class="template">
		<div class="form">
			<el-form
			    ref="ruleForm"
			    :model="ruleForm"
			    :rules="rules"
			    label-width="120px"
			    class="demo-ruleForm"
				label-position="top"
			  >
			    <el-form-item label="邮箱" prop="urEmail">
					<el-input v-model="ruleForm.urEmail" />
			    </el-form-item>
				<el-form-item label="昵称" prop="urName">
					<el-input v-model="ruleForm.urName" />
				</el-form-item>
				<el-form-item label="个人简介" prop="urDes">
					<el-input type="textarea" :rows="4" v-model="ruleForm.urDes" />
				</el-form-item>
			    <el-form-item>
					<el-button type="primary" style="width:100%;" @click="saveForm('ruleForm')">保存</el-button>
			    </el-form-item>
			  </el-form>
		</div>
    </div>
</template>
<script>
export default {
	data() {
		const validateEmail = (rule,value, callback) => {
			var reg=/^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
			if(!reg.test(value)){ 
				callback(new Error('请输入正确的邮箱'))
			} else {
				callback()
			}
		}
		return{
			ruleForm:{
				urName:'',
				urEmail:'',
				urDes:'',
				urId: window.localStorage.getItem("urId")
			},
			rules: {
				urName: [
					{ required: true, message: '昵称不能为空', trigger: 'change' }
				],
				// urEmail: [
				// 	{ required: true, validator: validateEmail, trigger: 'change' }
				// ],
				urDes:[
					{ required: true, message: '个人简介不能为空', trigger: 'change' }
				]
			},
		}
	},
	created(){
		this.getObject();
	},
	methods:{
		saveForm(formName){
			let that = this;
			that.$refs[formName].validate((valid) => {
				if (valid) {
					this.$axios({
						method:'post',
						url: 'user/ur/setBasic',
						headers: {
							token: window.localStorage.getItem("token")
						},
						data:this.$qs.stringify(that.ruleForm)
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg==2){
							that.$message.success("保存成功!");
							that.getObject();
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				} else {
					return false;
				}
			});
		},
		getObject(){
			let that = this;
			this.$axios({
				method:'post',
				url: 'user/ur/getObject',
				headers: {
					token: window.localStorage.getItem("token")
				},
				data:this.$qs.stringify({
					urId: window.localStorage.getItem("urId")
				})
			})
			.then((res)=>{
				that.ruleForm = res.data.data;
			})
			.catch((err)=>console.log('请求失败',err))
		},
	}
};
</script>
<style scoped>
	.form{
		width:500px;
		margin-top:15px;
		margin-left:15px;
	}
</style>
